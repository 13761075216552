import logo from "./logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Button, Form, Input, InputGroup, InputGroupText } from "reactstrap";
import { useState } from "react";
// import axios from "axios";
function App() {
    const [email, setEmail] = useState("");
    const [deleted, setDeleted] = useState(0);

    const Submit = async () => {
        // axios.delete("https://api.mondaa.com.au/user/delete/" + email);
        // await setTimeout(() => {}, 50000);
        setDeleted(true);
    };

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>Mondaa Account Deletion</p>
                {deleted === 0 ? (
                    <Form onSubmit={Submit}>
                        <InputGroup
                            style={{ width: "100%", marginBottom: "25px" }}
                        >
                            <InputGroupText>Email</InputGroupText>
                            <Input
                                value={email}
                                onChange={(kk) => {
                                    setEmail(kk.target.value);
                                }}
                            />
                        </InputGroup>
                        <Button onClick={Submit}>Submit</Button>
                    </Form>
                ) : (
                    <div>
                        <p style={{ color: "red" }}>
                            Account Deletion Page is in maintenance. Please Come
                            back later.
                        </p>
                    </div>
                )}
            </header>
        </div>
    );
}

export default App;
